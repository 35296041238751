<template>
    <iframe
        v-if="showIframe"
        :src="$universe.data().home_url"
        class="fill-height fill-width home"
    />
    <component :is="universeComponent" v-else class="fill-height fill-width home" />
</template>

<script>
const PhebeHome = () => import("./phebe/PhebeHome");
const KyoseilHome = () => import("./kyoseil/KyoseilHome");
const MutualizeHome = () => import("./mutualize/MutualizeHome");
const NeotreesHome = () => import("./neotrees/NeotreesHome");
const ArthaudHome = () => import("./arthaud/ArthaudHome");
const DataFactoryHome = () => import("./datafactory/DataFactoryHome");
const MagnitudeHome = () => import("./magnitude/MagnitudeHome");
const StarkIncHome = () => import("./starkInc/StarkIncHome");

export default {
    name: "Home",
    component: [
        PhebeHome,
        KyoseilHome,
        MutualizeHome,
        NeotreesHome,
        ArthaudHome,
        DataFactoryHome,
        MagnitudeHome,
        StarkIncHome
    ],
    data() {
        return {
            showIframe: false,
            universeComponent: null
        };
    },
    beforeMount() {
        switch (this.$universe.slug()) {
            case "phebe":
                this.universeComponent = PhebeHome;
                break;
            case "kyoseil":
                this.universeComponent = KyoseilHome;
                break;
            case "mutualize":
                this.universeComponent = MutualizeHome;
                break;
            case "neotrees":
                this.universeComponent = NeotreesHome;
                break;
            case "arthaud":
                this.universeComponent = ArthaudHome;
                break;
            case "datafactory":
                this.universeComponent = DataFactoryHome;
                break;
            case "magnitude":
                this.universeComponent = MagnitudeHome;
                break;
            case "stark-inc":
                this.universeComponent = StarkIncHome;
                break;
            default:
                this.universeComponent = null;
                break;
        }
        const homeUrl = this.$universe.data().home_url;
        if (homeUrl === "https://test.com") {
            this.showIframe = false;
        } else {
            this.showIframe = !!homeUrl;
        }
    }
};
</script>

<style lang="scss" scoped>
.home {
    padding: 0px !important;
    border-width: 0px !important;
    background-color: #f3f2ff;
}
</style>
