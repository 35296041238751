import Vue from "vue";

function intercomShowHide(show = true) {
    const chatbot = window.Intercom;
    if (chatbot) {
        chatbot(show ? "show" : "hide");
        // chatbot("update", { hide_default_launcher: !show });
    }
}

function intercomUpdateWithOptions(options) {
    const chatbot = window.Intercom;
    if (chatbot) {
        // console.log({ options, });
        chatbot("update", options);
        intercomShowHide(false);
    }
}

function initIntercomChat() {
    window.intercomSettings = {
        app_id: "m3sb7n62",
        custom_launcher_selector: "#eqs-chatbot-activator",
        hide_default_launcher: false
        // created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp
    };
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://widget.intercom.io/widget/m3sb7n62";
                var x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
            };
            if (w.attachEvent) {
                w.attachEvent("onload", l);
            } else {
                w.addEventListener("load", l, false);
            }
        }
    })();
}

var state = Vue.observable({
    opened: false
});

export class ChatbotService {
    constructor(dev, time, language) {
        if (!dev) {
            throw new Error("Missing `dev` option");
        }
        if (!time) {
            throw new Error("Missing `time` option");
        }
        if (!language) {
            throw new Error("Missing `language` option");
        }
        this.dev = dev;
        this.time = time;
        this.language = language;
        this.state = state;
        if (this.dev.isProd()) {
            initIntercomChat();
            window.Intercom("onHide", () => {
                this.showChatbot = false;
                state.opened = false;
            });
            window.Intercom("onShow", () => {
                this.showChatbot = true;
                state.opened = true;
            });
            // by default hide the chatbot after loading the script
            this.showChatbot = false;
            intercomShowHide(false);
        }
    }
    toggle() {
        intercomShowHide(this.showChatbot);
    }
    display(show = true) {
        this.showChatbot = show;
        intercomShowHide(show);
    }
    populateEntity(entity) {
        if (!entity) return;
        intercomUpdateWithOptions({
            name: entity.name, // Full name
            email: entity.email, // Email address
            user_id: entity.id,
            phone: entity.phone,
            avatar: entity.avatar?.upload,
            created_at: this.time.getUnixTime(entity.date_joined),
            language_override: this.language.current()
        });
    }
}
