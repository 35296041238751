var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height fill-width",attrs:{"flat":""}},'EqsCard',{
        color: 'transparent',
        eqsType: 'no-padding',
        loading: !_vm.signer.member_sign_page_iframe,
        height: '100%',
        elClass: 'yousign-iframe-container'
    },false),[_c('div',{staticClass:"d-flex flex-column fill-height fill-width",attrs:{"el-class":`signature-${_vm.formData.signature_done ? 'done' : 'pending'}`}},[_c('v-input',_vm._b({staticClass:"fill-width pl-3 custom-info",attrs:{"value":_vm.formData.signature_done}},'v-input',_vm.getInputState,false)),_c('iframe',{staticClass:"yousign-iframe",staticStyle:{"height":"100%","width":"100%","border-width":"0px"},attrs:{"src":_vm.signer.member_sign_page_iframe}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }