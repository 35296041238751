export default {
    shareledgers: state => state.shareledgers,
    shareledgersLoading: state => state.shareledgersLoading,
    capital: state =>
        state.shareledgers.reduce(
            (acc, cur) =>
                acc +
                (cur.share_type === "shares" && cur.share_detail
                    ? parseFloat(cur.numberofshares) * parseFloat(cur.share_detail.nominal_value)
                    : 0),
            0
        ),
    total_number_of_shares: state =>
        state.shareledgers.reduce(
            (acc, cur) => acc + (cur.share_type === "shares" ? parseFloat(cur.numberofshares) : 0),
            0
        ),
    valorisation: state =>
        state.shareledgers.reduce(
            (acc, cur) =>
                acc +
                (cur.share_type === "shares"
                    ? parseFloat(cur.numberofshares) * parseFloat(cur.lastpricepershare)
                    : 0),
            0
        )
};
