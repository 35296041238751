<template>
    <v-card :max-width="maxWidthCard" class="mx-auto" outlined>
        <v-list-item one-line>
            <v-list-item-content>
                <v-list-item-title class="text-h6 mb-1">
                    {{ capinc.order_title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    Via
                    {{ capinc.shareledger?.company?.name || "-" }}
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="grey" rounded="md">
                <v-img :src="capinc.logo ? capinc.logo.upload : ''" />
            </v-list-item-avatar>
        </v-list-item>
        <v-card-text style="color: black !important">
            <v-row>
                <v-col>{{ $t("user.buy.steps.sub-card.unit-price") }}</v-col>
                <v-col>{{ sharePriceFormatted }}</v-col>
            </v-row>
            <v-row>
                <v-col>{{ $t("user.buy.steps.sub-card.n-shares") }}</v-col>
                <v-col>{{ shareAmountLocal }}</v-col>
            </v-row>
            <v-divider />
            <v-row>
                <v-col>{{ $t("user.buy.steps.sub-card.invested-amount") }}</v-col>
                <v-col>{{ $currency.displayWithCurrency(orderAmount, capinc.currency) }}</v-col>
            </v-row>
            <v-row>
                <v-col
                    >{{
                        capinc.universe === "kyoseil"
                            ? $t("user.buy.steps.sub-card.fees")
                            : $t("user.buy.steps.sub-card.fees-nokyoseil")
                    }}<span class="font-weight-black">{{
                        feePercentageLocal !== null ? ` (${feePercentageLocal}%)` : ""
                    }}</span></v-col
                >
                <v-col>{{ $currency.displayWithCurrency(feesAmount, capinc.currency) }}</v-col>
            </v-row>
            <v-row v-if="sgpFeePercent">
                <v-col
                    >{{ $t("user.buy.steps.sub-card.sgp-fees")
                    }}<span class="font-weight-black">{{
                        sgpFeePercent !== null ? ` (${sgpFeePercent}%)` : ""
                    }}</span></v-col
                >
                <v-col>{{ $currency.displayWithCurrency(sgpFeeAmount, capinc.currency) }}</v-col>
            </v-row>
            <v-row v-if="catchupFeesData">
                <v-col>{{ $t("user.buy.steps.sub-card.catchup-fees") }}</v-col>
                <v-col>{{
                    $currency.displayWithCurrency(catchupFeesAmount, capinc.currency)
                }}</v-col>
            </v-row>
            <v-divider />
            <v-row align="center">
                <v-col class="font-weight-black">{{
                    $t("user.buy.steps.sub-card.total-amount")
                }}</v-col>
                <v-col class="font-weight-black text-h6">
                    <transition name="slide-fade" mode="out-in">
                        <div :key="totalAmount">
                            {{ $currency.displayWithCurrency(totalAmount, capinc.currency) }}
                        </div>
                    </transition>
                </v-col>
            </v-row>
            <div v-if="showDetails" class="pa-0 ma-0">
                <v-divider />
                <v-row v-if="paymentSelected">
                    <v-col>{{ $t("user.buy.steps.sub-card.payment-method") }}</v-col>
                    <v-col>{{ paymentSelected }}</v-col>
                </v-row>
                <v-row v-if="taxArrangementSelected">
                    <v-col>{{ $t("user.buy.steps.sub-card.tax-arrangement") }}</v-col>
                    <v-col>{{ taxArrangementSelected }}</v-col>
                </v-row>
                <v-row v-if="envelopeSelected">
                    <v-col>{{ $t("user.buy.steps.sub-card.envelope") }}</v-col>
                    <v-col>{{ envelopeSelected }}</v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {
    getSelectedPayment,
    getShortTaxLabelFromId,
    getShortEnvelopeLabelFromId,
    getPaymentLabelFromId
} from "../util";

export default {
    name: "SubscriptionCard",
    props: {
        order: {
            type: Object,
            required: true
        },
        shareAmount: {
            type: Number,
            required: false,
            default: 0
        },
        feePercentage: {
            type: Number,
            required: false,
            default: 0.0
        },
        size: {
            type: String,
            required: false,
            default: "little"
        },
        showDetails: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        sgpFeePercent() {
            if (!this.capinc || this.capinc.universe !== "kyoseil") {
                return null;
            }
            const catchupFees = this.capinc?.capincpaymentoptions?.catchup_fees;
            if (!catchupFees) {
                return null;
            }
            let amount = parseFloat(catchupFees.sgp_fee);
            if (amount === NaN || amount === 0.0) {
                return null;
            }
            return amount;
        },
        sgpFeeAmount() {
            const sgpFeesPercent = this.sgpFeePercent;
            if (!sgpFeesPercent) {
                return null;
            }
            const sgpFeesAmount = this.getFeesAmount(this.orderAmount, sgpFeesPercent);
            return sgpFeesAmount;
        },
        catchupFeesData() {
            if (!this.capinc || this.capinc.universe !== "kyoseil") {
                return null;
            }
            const catchupFees = this.capinc?.capincpaymentoptions?.catchup_fees;
            if (!catchupFees) {
                return null;
            }
            let amount = parseFloat(catchupFees.amount);
            if (amount === NaN || amount === 0.0) {
                return null;
            }
            const dateToday = new Date();
            const dateClosing = new Date(catchupFees.initial_closing_date);
            if (dateClosing.getTime() >= dateToday.getTime()) {
                return null;
            }
            const daysDiff = Math.floor(Math.abs(dateToday - dateClosing) / (1000 * 60 * 60 * 24));
            return { amount, daysDiff };
        },
        catchupFeesAmount() {
            const catchupFeesData = this.catchupFeesData;
            if (!catchupFeesData?.amount) {
                return null;
            }
            let catchupFeesAmount = this.getFeesAmount(
                this.orderAmount,
                parseFloat(catchupFeesData.amount)
            );
            const daysPercent = (catchupFeesData.daysDiff * 100.0) / 365.0;
            catchupFeesAmount = this.getFeesAmount(catchupFeesAmount, daysPercent);
            return catchupFeesAmount;
        },
        shareAmountLocal() {
            if (this.order.capinc) {
                return this.order.numberofshares;
            } else {
                return this.shareAmount;
            }
        },
        feePercentageLocal() {
            if (this.order.capinc) {
                return this.order.order_fee_percentage;
            } else {
                return this.feePercentage;
            }
        },
        maxWidthCard() {
            if (this.size === "big") return "500";
            else if (this.size === "little") return "320";
            else return "320";
        },
        taxArrangementSelected() {
            const taxArrangement = this.order.tax_arrangement;
            if (taxArrangement) {
                return getShortTaxLabelFromId(this, taxArrangement.choice);
            } else {
                return null;
            }
        },
        envelopeSelected() {
            const envelope = this.order.envelope;
            if (envelope) {
                return getShortEnvelopeLabelFromId(this, envelope.choice);
            } else {
                return null;
            }
        },
        paymentSelected() {
            const paymentSelected = getSelectedPayment(this.order);
            if (paymentSelected) {
                return getPaymentLabelFromId(this, paymentSelected.name);
            } else {
                return null;
            }
        },
        capinc() {
            return this.order.object || this.order.capinc;
        },
        sharePrice() {
            return parseFloat(this.capinc.share_price);
        },
        sharePriceFormatted() {
            const sharePriceStr = this.sharePrice.toString().replace(".", ",");
            const symbol = this.capinc.currency === "EUR" ? "€" : "$";
            return `${sharePriceStr} ${symbol}`;
        },
        orderAmount() {
            return this.getInvestedAmount(this.shareAmountLocal, this.sharePrice);
        },
        feesAmount() {
            return this.getFeesAmount(this.orderAmount, this.feePercentageLocal);
        },
        totalAmount() {
            return this.getTotalAmount(
                this.orderAmount,
                this.feesAmount,
                this.catchupFeesAmount,
                this.sgpFeeAmount
            );
        }
    },
    methods: {
        getTotalAmount(investedAmount, feesAmount, catchupFeesAmount, sgpFeesAmount) {
            return investedAmount + feesAmount + catchupFeesAmount + sgpFeesAmount;
        },
        getFeesAmount(amount, feesPercentage) {
            const feesPercentageFloat = parseFloat(feesPercentage);
            let fees = parseFloat(amount * (feesPercentageFloat / 100.0));
            fees = isNaN(fees) ? 0.0 : Math.floor((fees + Number.EPSILON) * 100.0) / 100.0;
            return fees;
        },
        getInvestedAmount(amountOfShares, sharePrice) {
            const investedAmount = amountOfShares * sharePrice;
            return investedAmount;
        }
    }
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
    animation: pulse 0.25s;
}

.slide-fade-leave-active {
    animation: pulse 0.25s reverse;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: scale3d(1, 1, 1);
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.06, 1.06, 1.06);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
</style>
