<template>
    <EqsStepper
        :value.sync="step"
        :loading="loading || localLoading || shareledgersLoading || myProjectsLoading"
        closable
        style="height: 100%"
        v-bind="{
            closeInfoTxt,
            steps,
            progress
        }"
        @close="comeBackToLastRoute"
    >
        <template #default="{ item, loading: isLoading }">
            <div :class="item.class">
                <component
                    :is="item.is"
                    editable
                    :value.sync="formData.proceduredocument_set"
                    v-bind="{
                        formData,
                        capitalIncreaseId: capitalIncreaseId || capinc.id,
                        signature_procedure,
                        shareledgers,
                        schemaDouble,
                        deleteFunc: deleteProcedureDoc,
                        isRequired: false
                    }"
                    :loading="isLoading"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapGetters, mapActions } from "vuex";

import { procedureEditMixin } from "@/mixins/yousignMixin";

export default {
    name: "Form",
    components: {
        Parameters: () => import("./parameters/_Parameters"),
        Information: () => import("./information/_Information"),
        Members: () => import("./information/_Members"),
        Dataroom: () => import("./information/_Dataroom")
    },
    mixins: [stepperFormMixins, procedureEditMixin],
    props: {
        capitalIncreaseId: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            step: 0,
            capinc: {},
            populatedShareledgers: [],
            formData: {},
            schemaDouble: {},
            localLoading: false
        };
    },
    computed: {
        universeMail() {
            return this.$store.getters.universe.contact_email;
        },

        ...mapGetters(["shareledgers", "shareledgersLoading", "myProjectsLoading"]),

        edit() {
            return this.$route.name === "fundraising-edit";
        },
        closeInfoTxt() {
            return this.edit
                ? this.$t("capital-increase.admin.detail.come-back-to-dashboard")
                : this.$t("capital-increase.admin.detail.come-back-to-list");
        },
        steps() {
            return [
                {
                    name: "params",
                    title: this.$t("capital-increase.admin.detail.parameters"),
                    item: { is: "Parameters", class: "eqs-main-container margin-auto" },
                    back: { fn: this.comeBackToLastRoute, txt: this.closeInfoTxt },
                    next: { fn: this.edit ? this.updateCapIncParams : this.createCapInc },
                    schemaDouble: {
                        has_commitment: {
                            dataType: "bool",
                            isRequired: false,
                            default: false
                        },
                        pre_money_valuation: {
                            dataType: "float",
                            isRequired: false,
                            default: 0.0
                        },
                        soft_cap: {
                            dataType: "float",
                            isRequired: true
                        },
                        hard_cap: {
                            dataType: "float",
                            isRequired: true
                        },
                        min_investment: {
                            dataType: "float",
                            isRequired: true
                        },
                        max_investment: {
                            dataType: "float",
                            isRequired: true
                        },
                        start_date: {
                            isRequired: true
                        },
                        end_date: {
                            isRequired: true
                        },
                        shareledger: {
                            dataType: "id",
                            isRequired: true
                        },
                        share_premium: {
                            dataType: "float",
                            isRequired: false
                        },
                        share_price: {
                            dataType: "float",
                            isRequired: true
                        },
                        to_dollar: {
                            dataType: "bool",
                            isRequired: false,
                            default: false
                        },
                        cip: {
                            dataType: "bool",
                            isRequired: false,
                            default: false
                        }
                    }
                },
                {
                    name: "info",
                    title: this.$t("capital-increase.admin.detail.information-offering"),
                    item: { is: "Information", class: "eqs-main-container margin-auto" },
                    next: { fn: this.updateCapIncInfo },
                    schemaDouble: {
                        url: {
                            isRequired: false
                        },
                        order_title: {
                            isRequired: true
                        },
                        website_url: {
                            isRequired: false
                        },
                        logo: {
                            dataType: "file",
                            isRequired: true
                        },
                        image: {
                            dataType: "file",
                            isRequired: true
                        },
                        short_description: {
                            isRequired: true
                        },
                        tags: {
                            isRequired: false
                        },
                        long_description: {
                            isRequired: true
                        },
                        last_funding_round: {
                            isRequired: false
                        },
                        last_funding_amount: {
                            dataType: "float",
                            isRequired: false
                        }
                    }
                },
                {
                    name: "members",
                    title: this.$t("capital-increase.admin.detail.information-members"),
                    item: { is: "Members", class: "eqs-main-container margin-auto" },
                    next: { fn: this.updateCapIncInfo },
                    schemaDouble: {
                        id: {
                            isRequired: false
                        },
                        url: {
                            isRequired: false
                        },
                        team_size: {
                            dataType: "int",
                            isRequired: true
                        },
                        shareledger: {
                            dataType: "id",
                            isRequired: false
                        },
                        custodian: {
                            dataType: "id",
                            isRequired: false
                        },
                        member_set: {
                            isRequired: false
                        }
                    }
                },
                {
                    name: "dataroom",
                    title: this.$t("capital-increase.admin.detail.information-dataroom"),
                    item: { is: "Dataroom", class: "eqs-main-container margin-auto" },
                    next: { fn: this.updateCapIncInfo },
                    schemaDouble: {
                        id: {
                            isRequired: false
                        },
                        url: {
                            isRequired: false
                        },
                        // has_nda: {
                        //   dataType: "bool",
                        //   isRequired: false,
                        // },
                        // nda_to_sign: {
                        //   dataType: "multi-file",
                        //   isRequired: false,
                        // },
                        document_set: {
                            dataType: "multi-file",
                            isRequired: false
                        },
                        doc_pv: {
                            dataType: "file",
                            isRequired: false
                        },
                        doc_status: {
                            dataType: "file",
                            isRequired: false
                        },
                        doc_kbis: {
                            dataType: "file",
                            isRequired: false
                        }
                    }
                },
                {
                    name: "sign",
                    title: this.$t("capital-increase.admin.detail.docstosign"),
                    item: {
                        is: "GlobalSignatureTemplate",
                        class: "margin-auto fill-height"
                    },
                    next: { fn: this.finalize },
                    schemaDouble: {
                        proceduredocument_set: {
                            dataType: "array",
                            isRequired: false
                        }
                    }
                }
            ];
        }
    },
    watch: {
        capitalIncreaseId: {
            immediate: true,
            async handler(val) {
                if (!!val && val !== "add") {
                    await this.fetchCapInc(val);
                }
                this.getShareledgers();
                this.saveFormData();
            }
        },
        shareledgers() {
            this.getPopulatedShareledgers();
        },
        step: {
            immediate: true,
            handler(step) {
                this.schemaDouble = this.steps[step].schemaDouble;
                this.populateFormData();
                this.saveFormData();
            }
        }
        // "formData.pre_money_valuation"(val) {
        //     this.getSharePrice(
        //         val,
        //         this.formData.shareledger ? this.formData.shareledger.numberofshares : 0,
        //         this.formData.shareledger ? this.formData.shareledger.share_type : ""
        //     );
        // },
        // "formData.shareledger"(val) {
        //     this.getSharePrice(
        //         this.formData.pre_money_valuation,
        //         val ? val.numberofshares : 0,
        //         this.formData.shareledger ? this.formData.shareledger.share_type : ""
        //     );
        // }
    },
    methods: {
        ...mapActions(["getShareledgers", "updateMyProject", "fetchMyProject", "addMyProject"]),
        getPopulatedShareledgers() {
            this.populatedShareledgers = this.shareledgers.map(t => {
                let result = { ...t };
                Object.assign(result, this.getShareledgerTypeDetail(t));
                result.id = t.id;
                return result;
            });
            if (!!this.formData.shareledger) {
                const shareledger = this.shareledgers.find(
                    ledger => ledger.url && ledger.url === this.formData.shareledger.url
                );

                if (!!shareledger) this.$set(this.formData, "shareledger", shareledger);
            }
        },
        async finalize() {
            try {
                await this.updateCapIncProcedure();
                this.$router.push({
                    name: "fundraising-details",
                    params: {
                        ...this.$route.params,
                        capitalIncreaseId: this.capinc.id
                    }
                });
            } catch (e) {
                throw e;
            }
        },
        comeBackToLastRoute() {
            if (this.edit)
                this.$router.push({
                    name: "fundraising-details",
                    params: {
                        ...this.$route.params,
                        capitalIncreaseId: this.capitalIncreaseId
                    }
                });
            else this.$emit("close");
        },
        // getSharePrice(preMoneyVal, numberofshares, share_type) {
        //     if (share_type === "bsa_air") {
        //         this.formData.share_price = 1.0;
        //         if (preMoneyVal !== numberofshares) {
        //             this.formData.pre_money_valuation = numberofshares;
        //         }
        //     } else if (share_type === "bonds") {
        //         this.formData.share_price = this.formData.shareledger.lastpricepershare;
        //     } else if (numberofshares > 0) {
        //         this.formData.share_price = this.$currency.divide(preMoneyVal, numberofshares);
        //     }
        // },
        async fetchCapInc(id) {
            try {
                this.localLoading = true;
                this.capinc = await this.fetchMyProject({
                    projectId: id,
                    entityId: this.$entity.represented().id
                });
                this.capinc.to_dollar = this.capinc.currency === "USD";
                const procedure_id = this.getProcedureIdFromURL(this.capinc.signature_procedure);
                await this.getProcedure(procedure_id);
                this.step = this.$route.query.created
                    ? 1
                    : this.getLastFormStep({
                          ...this.capinc,
                          ...this.signature_procedure
                      });
                this.populateFormData();
            } catch (e) {
                this.$ui.error(e, "capital_increase_admin_get_last");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        populateFormData() {
            // todo fix here the empty proceduredocument_set after GlobalSignatureTemplate updated formData with first empty doc
            let res = this.capinc
                ? {
                      ...this.capinc,
                      proceduredocument_set:
                          this.signature_procedure.proceduredocument_set &&
                          this.signature_procedure.proceduredocument_set.length
                              ? this.signature_procedure.proceduredocument_set
                              : this.formData.proceduredocument_set
                  }
                : {};
            if (
                !!this.schemaDouble.shareledger &&
                !!this.formData.shareledger &&
                this.populatedShareledgers.length > 0
            ) {
                const shareledger = this.populatedShareledgers.find(
                    ledger => ledger.url && ledger.url === this.formData.shareledger.url
                );

                if (!!shareledger) res.shareledger = shareledger;
                this.$set(this.formData, "shareledger", shareledger);
            }
            if (!!this.schemaDouble.member_set && !!this.formData.member_set) {
                const categories = ["team", "advisor", "partner"];
                categories.forEach(category => {
                    res[category] = this.formData.member_set.filter(
                        item => item.category === category
                    );
                });
            }
            this.buildFormData(this.schemaDouble, res);
        },
        getShareledgerTypeDetail(formData) {
            let res = {};
            switch (formData.share_type) {
                case "shares":
                    res = Object.assign(res, formData.share_detail);
                    break;
                case "units":
                    res = Object.assign(res, formData.share_detail);
                    break;
                case "bsa":
                    res = Object.assign(res, formData.bsa_detail);
                    break;
                case "bspce":
                    res = Object.assign(res, formData.bspce_detail);
                    break;
                case "bsa_air":
                    res = Object.assign(res, formData.bsa_air_detail);
                    break;
                case "aga":
                    res = Object.assign(res, formData.aga_detail);
                    break;
                case "bonds":
                    res = Object.assign(res, formData.bonds_detail);
                    break;
                case "tokens":
                    res = Object.assign(res, formData.tokens_detail);
                    break;
                case "carbo":
                    res = Object.assign(res, formData.carbo_detail);
                    break;
            }
            return res;
        },

        async createCapInc() {
            try {
                this.localLoading = true;
                const data = this.formatCapIncParams();
                this.capinc = await this.addMyProject(data);
                this.capinc.to_dollar = this.capinc.currency === "USD";
                this.step++;
                await this.$router.replace({
                    name: "fundraising-edit",
                    params: {
                        capitalIncreaseId: this.capinc.id
                    },
                    query: {
                        created: true
                    }
                });
            } catch (e) {
                if (e.status === 403)
                    this.$dialog.confirm({
                        title: this.$t("capital-increase.admin.form.not-allowed.dialog-title"),
                        text: this.$t("capital-increase.admin.form.not-allowed.dialog-txt", {
                            name: this.$entity.myEntity().name,
                            company: this.$entity.represented().name,
                            universeMail
                        }),
                        confirm: {
                            fn: () =>
                                this.$router.push({
                                    name: "operations",
                                    params: {
                                        id: this.$entity.represented().id
                                    }
                                }),
                            txt: this.$t("btn.continue")
                        }
                    });
                this.handleError(e, "capital_increase_admin_params_create");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateCapIncParams() {
            try {
                if (!this.errorNoFieldsChanged) {
                    this.localLoading = true;
                    let data = this.formatCapIncParams();
                    Object.assign(data, { id: this.capitalIncreaseId || this.capinc.id });
                    this.capinc = await this.updateMyProject(data);
                }
                this.step++;
            } catch (e) {
                this.handleError(e, "capital_increase_admin_params_update");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateCapIncProcedure() {
            this.localLoading = true;
            try {
                await this.updateProcedureDocs();
                if (!this.edit)
                    this.$dialog.info({
                        title: this.$t("capital-increase.admin.form.information-title"),
                        text: this.$t("capital-increase.admin.form.information-msg"),
                        confirm: {
                            fn: () => {},
                            txt: this.$t("btn.continue")
                        }
                    });
                this.step++;
            } catch (e) {
                this.handleError(e, "capital_increase_admin_params_update");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateCapIncInfo(noNext = false) {
            try {
                this.localLoading = true;
                if (!this.errorNoFieldsChanged) {
                    let data = this.formatCapIncInfo();
                    Object.assign(data, { id: this.capitalIncreaseId || this.capinc.id });
                    this.capinc = await this.updateMyProject(data);
                }
                this.capinc.to_dollar = this.capinc.currency === "USD";
                if (!noNext) this.step++;
            } catch (e) {
                this.handleError(e, "capital_increase_admin_params_update");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        formatCapIncParams() {
            return this.formatFormData(this.schemaDouble, {
                share_premium_currency: "EUR",
                pre_money_valuation_currency: "EUR",
                soft_cap_currency: "EUR",
                hard_cap_currency: "EUR",
                min_investment_currency: "EUR",
                max_investment_currency: "EUR"
            });
        },
        formatCapIncInfo() {
            let data = this.formatFormData(this.schemaDouble, {
                last_funding_amount_currency: "EUR"
            });
            if (data.tags) {
                data.tags = data.tags.filter(v => typeof v === "string" && v.trim().length > 0);
            }
            return data;
        }
    }
};
</script>

<style lang="scss" scoped></style>
