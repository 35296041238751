var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({attrs:{"label":_vm.$t('signatures.procedure.name.label'),"placeholder":_vm.$t('signatures.procedure.name.placeholder'),"value":_vm.formData.name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "name", $event)}}},'EqsText',{ loading: _vm.loading, ..._vm.schemaDouble.name },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextArea',_vm._b({attrs:{"label":_vm.$t('signatures.procedure.description.label'),"placeholder":_vm.$t('signatures.procedure.description.placeholder'),"value":_vm.formData.description},on:{"update:value":function($event){return _vm.$set(_vm.formData, "description", $event)}}},'EqsTextArea',{
                            loading: _vm.loading,
                            ..._vm.schemaDouble.description,
                            rows: 2,
                            counter: 255
                        },false))],1)],1)],1),_c('v-col',{staticClass:"eqs-form-field fill-height",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsLabel',_vm._b({staticClass:"pa-3",attrs:{"editable":"","value":_vm.$t('signatures.add.signers')}},'EqsLabel',{ loading: _vm.loading, ..._vm.schemaDouble.signer_set },false),[_c('GlobalSelectInvestorTable',_vm._b({staticClass:"fill-height",attrs:{"value":_vm.formData.signer_set},on:{"update:value":function($event){return _vm.$set(_vm.formData, "signer_set", $event)}}},'GlobalSelectInvestorTable',{
                        loading: _vm.loading,
                        ..._vm.schemaDouble.signer_set
                    },false))],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }