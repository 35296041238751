<template>
    <div class="eqs-chatbot" :el-class="elClass">
        <v-card
            id="eqs-chatbot-activator"
            flat
            class="eqs-chatbot__container d-flex flex-row"
            :color="$color.colors.menuColor"
            :el-class="`${elClass}-activator`"
            :disabled="loading"
            @click.prevent="toggleChat"
        >
            <v-col align-self="center">
                <EqsSkeleton v-bind="{ loading, type: 'button' }">
                    <v-icon
                        :color="opened ? 'primary' : $color.colors.lightGrey"
                        :size="isMobile ? '26px' : '30px'"
                    >
                        mdi-chat
                    </v-icon>
                </EqsSkeleton>
            </v-col>
            <v-divider vertical />
        </v-card>
    </div>
</template>

<script>
export default {
    name: "InternalChat",
    components: {
        EqsSkeleton: () => import("../../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    props: {
        elClass: {
            type: String,
            default: "eqs-chatbot"
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        opened() {
            return this.$chatbot?.state?.opened;
        }
    },
    methods: {
        toggleChat() {
            this.$parent.$parent.$emit("toggleChat");
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../../shared/styles/components/organisms/InternalChat.scss";
</style>
