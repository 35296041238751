<template>
    <div class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsSelect
                    :value.sync="formData.share_type"
                    :items="share_type"
                    item-value="id"
                    item-text="name"
                    v-bind="{
                        elClass: 'share-type',
                        label: $t('shareledger.dialog.stepper.tokenize.label-type-selector'),
                        editable: creation && editable,
                        loading,
                        ...schemaDouble.share_type,
                        placeholder: share_type[0].name
                    }"
                />
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsText
                    :label="$t('shareledger.dialog.stepper.tokenize.label-share')"
                    :placeholder="$t('shareledger.dialog.stepper.tokenize.label-share-placeholder')"
                    :value.sync="formData.name"
                    v-bind="{
                        elClass: 'name-of-the-shares',
                        editable,
                        loading,
                        rules: [checkName, protectedCharR],
                        ...schemaDouble.name
                    }"
                />
            </v-col>

            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsText
                    :label="$t('shareledger.dialog.stepper.tokenize.label-ticker')"
                    placeholder="AO"
                    :value.sync="formData.symbol"
                    :rules="[checkTicker]"
                    v-bind="{
                        elClass: 'alias-ticker',
                        editable,
                        loading,
                        counter: 24,
                        ...schemaDouble.symbol
                    }"
                />
            </v-col>
        </v-row>

        <component
            :is="selectedShareType.is"
            v-if="!!selectedShareType.is"
            v-bind="{
                ...$props,
                ...$attrs,
                conversionShareledgers
            }"
        />

        <v-row v-if="!!selectedShareType.is && (formData.docshareledger || editable)" class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsFileInput
                    :value.sync="formData.docshareledger"
                    v-bind="{
                        elClass: 'existing-ledger-file',
                        label: $t('shareledger.dialog.stepper.tokenize.placeholder-scan'),
                        editable,
                        loading,
                        ...schemaDouble.docshareledger
                    }"
                />
            </v-col>
        </v-row>

        <v-scroll-y-transition hide-on-leave>
            <GlobalBlockchainLoader v-if="loading" v-bind="{ height: '24vh' }" />
        </v-scroll-y-transition>
    </div>
</template>

<script>
import { greaterThanZeroR, protectedCharR } from "@equisafe-ui-vue/src/rules";
import { share_type } from "@/services/shareledger";
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "ShareledgerSpecification",
    components: {
        AGASpecification: () => import("./specification/AGASpecification.vue"),
        TokensSpecification: () => import("./specification/TokensSpecification.vue"),
        CarboSpecification: () => import("./specification/CarboSpecification.vue"),
        BSASpecification: () => import("./specification/BSASpecification.vue"),
        BSAAIRSpecification: () => import("./specification/BSAAIRSpecification.vue"),
        BSPCESpecification: () => import("./specification/BSPCESpecification.vue"),
        GeneralSpecification: () => import("./specification/GeneralSpecification.vue"),
        ShareSpecification: () =>
            import(
                "@/components/views/shareledger/capitalisation/digitalizeLedger/tokenizeYourShares/specification/ShareSpecification"
            ),
        BondsSpecification: () =>
            import(
                "@/components/views/shareledger/capitalisation/digitalizeLedger/tokenizeYourShares/specification/BondsSpecification"
            ),
        GlobalBlockchainLoader: () =>
            import("@/components/global/globalBlockchainLoader/GlobalBlockchainLoader")
    },
    mixins: [formMixin],
    props: {
        shareledgers: {
            type: Array,
            required: true
        },
        creation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            protectedCharR,
            greaterThanZeroR,
            share_type
        };
    },
    computed: {
        names() {
            return this.shareledgers.map(shareledger => shareledger.name.toLowerCase());
        },
        tickers() {
            return this.shareledgers.map(shareledger => shareledger.symbol);
        },
        selectedShareType() {
            return this.share_type.find(({ id }) => id == this.formData.share_type) || {};
        },
        conversionShareledgers() {
            return this.shareledgers.filter(({ share_type }) => share_type == "shares");
        }
    },
    methods: {
        checkName(val) {
            const name = !!val ? val.toLowerCase() : "";
            if (name && this.names.includes(name))
                return this.$t("shareledger.dialog.stepper.tokenize.name-error");
            return true;
        },
        checkTicker(val) {
            const ticker = val;
            if (ticker && this.tickers.includes(ticker))
                return this.$t("shareledger.dialog.stepper.tokenize.ticker-error");
            return true;
        }
    }
};
</script>
