import store from "@/store/store";
import { PRODUCTION_ENVIRONMENT, ACTIVATE_DEBUGGER_EVENTS_VUE_JS } from "@/assets/constants";

export default function (...args) {
    if (PRODUCTION_ENVIRONMENT) {
        return;
    }
    if (ACTIVATE_DEBUGGER_EVENTS_VUE_JS) {
        return store.commit(...args);
    }
}
