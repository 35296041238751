<template>
    <EqsCard
        class="fill-height"
        v-bind="{
            loading,
            height: '100%'
        }"
    >
        <template #main>
            <EqsStepper
                style="height: 100%"
                class="fill-height d-flex"
                :value.sync="step"
                closable
                v-bind="{
                    steps,
                    progress,
                    closeInfoTxt: $t('shareledger.dialog.stepper.close'),
                    checkProgress: currStep.checkProgress
                }"
                @close="() => $emit('closeDigitalizeLedger')"
            >
                <template #default="{ item }">
                    <div :class="`${currStep.noMainForm ? '' : 'eqs-main-container'}  margin-auto`">
                        <EqsTextDisplay
                            v-if="item.text"
                            :value="item.text"
                            class="text-center pb-5"
                        />
                        <component
                            :is="item.is"
                            class="fill-height fill-width"
                            v-bind="{
                                formData,
                                schemaDouble,
                                shareledgers,
                                loading: localLoading
                            }"
                        />
                    </div>
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import TokenizeYourShares from "./tokenizeYourShares/_TokenizeYourShares.vue";

import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { formatShareledgerFormMixin, getShareledgerFormMixin } from "@/mixins/shareledgerMixin";

import { mapActions } from "vuex";

export default {
    name: "DigitalizeLedger",
    components: {
        TokenizeYourShares
    },
    mixins: [stepperFormMixins, formatShareledgerFormMixin, getShareledgerFormMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        fetch: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            step: 0,
            formData: {},
            schemaDouble: this.getSchemaDoubleByLedgerType(),
            localLoading: false,
            steps: [
                {
                    title: this.$t("shareledger.dialog.stepper.tokenize-your-shares.title"),
                    checkProgress: true,
                    next: {
                        fn: this.saveShareledgerDetails,
                        txt: this.$t(
                            "shareledger.dialog.stepper.tokenize-your-shares.create-shareledger"
                        )
                    },
                    back: {
                        fn: () => this.$emit("closeDigitalizeLedger"),
                        txt: this.$t("shareledger.dialog.stepper.close")
                    },
                    item: {
                        is: "TokenizeYourShares",
                        text: this.$t(
                            "shareledger.dialog.stepper.tokenize-your-shares.specification-ledger-txt"
                        )
                    }
                }
            ]
        };
    },
    computed: {
        currStep() {
            return this.steps[this.step];
        }
    },
    watch: {
        "formData.share_type": {
            immediate: true,
            handler(val) {
                this.schemaDouble = this.getSchemaDoubleByLedgerType(val);
                this.buildFormData(this.schemaDouble, this.formData);
                if (["shares", "units"].includes(val) && !this.formData.number_of_vote)
                    this.$set(this.formData, "number_of_vote", 1);
            }
        }
    },
    created() {
        this.getShareledgers();
        this.buildFormData(this.schemaDouble);
    },
    methods: {
        ...mapActions(["fetchShareledger"]),
        async saveShareledgerDetails() {
            try {
                this.localLoading = true;
                const res = await this.$blockchain.deploySecurityToken(
                    this.formatShareledgerDetails()
                );
                await this.fetchShareledger(res.data.id);
                this.$emit("closeDigitalizeLedger");
            } catch (e) {
                this.handleError(e, "shareledger_creation");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<!--todo: removed css, double check here-->
