import { i18n } from "@/services/plugins/i18n.js";

export class UniverseService {
    constructor(api, store) {
        if (!api) throw new Error("Missing `api` option");
        if (!store) {
            throw new Error("Missing `store` option");
        }
        if (!i18n) {
            throw new Error("Missing `i18n` option");
        }
        this.api = api;
        this.store = store;
        this.i18n = i18n;
    }

    image(path) {
        const images = this.data().images || {};
        const pathNoExtension = path.split(".").shift();
        const imgPath = !this.isEquisafe() ? images[pathNoExtension] : path;
        return imgPath || path;
    }

    data() {
        return this.store.getters.universe;
    }

    name() {
        return this.data().name || "equisafe";
    }

    slug() {
        return this.data().slug || "equisafe";
    }

    isExternKyc() {
        // TODO : params dans le model Universe
        return this.isNeotrees();
    }

    publicName() {
        return this.data().public_name || "Equisafe";
    }

    isNeotrees() {
        return this.slug().localeCompare("neotrees") === 0;
    }

    isMyClubDeal() {
        return this.slug().localeCompare("myclubdeal") === 0;
    }

    isMutualize() {
        return this.slug().localeCompare("mutualize") === 0;
    }

    isKyoseil() {
        return this.slug().localeCompare("kyoseil") === 0;
    }

    isPhebe() {
        return this.slug().localeCompare("phebe") === 0;
    }

    isArthaud() {
        return this.slug().localeCompare("arthaud") === 0;
    }

    isDataFactory() {
        return this.slug().localeCompare("datafactory") === 0;
    }

    isMagnitude() {
        return this.slug().localeCompare("magnitude") === 0;
    }

    isStarkInc() {
        return this.slug().localeCompare("stark-inc") === 0;
    }

    isEquisafe() {
        return this.slug().localeCompare("equisafe") === 0;
    }

    scripts() {
        return this.data().scripts || {};
    }

    restrictedRoutes() {
        return this.store.getters.routes_restrictions;
    }

    themes() {
        return this.data().themes || {};
    }

    getDefaultRoute(entity) {
        let route = "";
        switch (this.name()) {
            case "nexilocus":
                route = "/projects";
                break;
            case "Equisafe":
                route = `/dashboard/${entity.id}`;
                break;
            default:
                route = `/home/${entity.id}`;
                break;
        }
        return route;
    }

    setFaviconAndTitle() {
        const iconPath = this.image("/images/favicon.svg");
        const favicon = document.getElementById("favicon");
        favicon.href = iconPath;
        const title = document.getElementById("browser-bar-title");
        const universeName = this.publicName();
        title.textContent = universeName;
    }
}
