import { share_type as share_types } from "@/services/shareledger";
import { mapGetters, mapActions } from "vuex";
import { buildFormDataMixin, formatFormDataMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export const getShareledgerFormMixin = {
    mixins: [buildFormDataMixin],
    data() {
        return {
            selectedShareType: {},
            schemaDouble: {}
        };
    },
    computed: mapGetters(["shareledgersLoading", "shareledgers"]),
    methods: {
        ...mapActions(["getShareledger", "getShareledgers"]),
        getSchemaDoubleByLedgerType(type) {
            this.selectedShareType = share_types.find(({ id }) => id == type);
            let res = {
                docshareledger: {
                    dataType: "id",
                    isRequired: false
                },
                name: {
                    isRequired: true
                },
                symbol: {
                    isRequired: true
                },
                share_type: {
                    isRequired: true
                }
            };
            if (this.selectedShareType && this.selectedShareType.schemaDouble)
                res = {
                    ...res,
                    ...this.selectedShareType.schemaDouble
                };
            return res;
        },
        getShareTypeName(shareledger) {
            let type = share_types.find(share => share.id === shareledger.share_type);
            return !!type ? type.name : "";
        },
        getShareledgerTypeDetail(shareledger) {
            let res = {};
            switch (shareledger.share_type) {
                case "shares":
                    res = Object.assign(res, shareledger.share_detail);
                    break;
                case "units":
                    res = Object.assign(res, shareledger.share_detail);
                    break;
                case "bsa":
                    res = Object.assign(res, shareledger.bsa_detail);
                    break;
                case "bspce":
                    res = Object.assign(res, shareledger.bspce_detail);
                    break;
                case "bsa_air":
                    res = Object.assign(res, shareledger.bsa_air_detail);
                    break;
                case "aga":
                    res = Object.assign(res, shareledger.aga_detail);
                    break;
                case "bonds":
                    res = Object.assign(res, shareledger.bonds_detail);
                    break;
            }
            return res;
        },
        async populateShareledgerForm(shareledgerId) {
            let shareledger = await this.getShareledger(shareledgerId);
            shareledger = {
                ...this.getShareledgerTypeDetail(shareledger),
                ...shareledger
            };
            this.schemaDouble = this.getSchemaDoubleByLedgerType(shareledger.share_type);
            this.buildFormData(this.schemaDouble, shareledger);
        }
    }
};

export const formatShareledgerFormMixin = {
    mixins: [formatFormDataMixin],
    methods: {
        formatShareledgerDetails(isPatch = false) {
            const {
                docshareledger_id,
                name,
                symbol,
                share_type,
                numberofshares,
                lastpricepershare,
                share_transfer,
                share_only_investor_transfer,
                pool_size,
                ...formData
            } = this.formatFormData(this.schemaDouble);

            let data = {
                docshareledger_id,
                name,
                symbol,
                share_type,
                numberofshares,
                lastpricepershare,
                share_transfer,
                share_only_investor_transfer,
                pool_size
            };
            if (!isPatch) data.numberofshares = 0;
            switch (share_type) {
                case "shares":
                    data.share_detail = formData;
                    break;
                case "units":
                    data.share_detail = formData;
                    break;
                case "bsa":
                    data.bsa_detail = formData;
                    if (!isPatch) data.lastpricepershare = formData.souscription_price;
                    break;
                case "bspce":
                    data.bspce_detail = formData;
                    console.log({ formData });
                    if (!isPatch) data.lastpricepershare = 0;
                    break;
                case "bsa_air":
                    data.bsa_air_detail = formData;
                    if (!isPatch) data.lastpricepershare = 1;
                    break;
                case "aga":
                    data.aga_detail = formData;
                    if (!isPatch) data.lastpricepershare = 0;
                    break;
                case "bonds":
                    data.bonds_detail = formData;
                default:
                    data = { ...data, ...formData };
            }
            return data;
        }
    }
};
