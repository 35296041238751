/* eslint-disable vue/max-len */
// Global Components

import GlobalCopyBadge from "@/components/molecules/badge/GlobalCopyBadge.vue";
import GlobalTransactionChip from "@/components/molecules/chip/GlobalTransactionChip";
import GlobalPlatformsLinks from "@/components/molecules/hyperlinks/GlobalPlatformsLinks.vue";
import GlobalChooseEntity from "@/components/molecules/form/GlobalChooseEntity.vue";
import GlobalInviteEntityDialog from "@/components/molecules/dialog/GlobalInviteEntityDialog";
import GlobalCompanyInviteByInvestorDialog from "@/components/molecules/dialog/GlobalCompanyInviteByInvestorDialog.vue";
import GlobalPeopleList from "@/components/global/globalPeopleList/GlobalPeopleList.vue";
import GlobalDocWithCategoryList from "@/components/global/globalDocWithCategoryList/GlobalDocWithCategoryList.vue";
import GlobalFinalState from "@/components/global/globalFinalState/GlobalFinalState.vue";
import GlobalInviteInvestorDialog from "@/components/global/globalInviteInvestorDialog/GlobalInviteInvestorDialog.vue";
import GlobalExplorerDialog from "@/components/molecules/dialog/GlobalExplorerDialog.vue";
import GlobalChooseInvestor from "@/components/global/globalChooseInvestor/GlobalChooseInvestor.vue";
import GlobalChooseShareledger from "@/components/global/globalChooseShareledger/GlobalChooseShareledger.vue";
import GlobalSelectInvestorTable from "@/components/global/globalSelectInvestorTable/GlobalSelectInvestorTable.vue";
import GlobalYousignIFrame from "@/components/global/globalYousignIFrame/GlobalYousignIFrame.vue";

// service
import { APIService } from "@/services/api";
import { EntityService } from "@/services/entity";
import { KYCService } from "@/services/kyc";
import { RoleService } from "@/services/role";
import { BlockchainService } from "@/services/blockchain/blockchain";
import { NotificationsService } from "@/services/notifications";
import { ShareledgerService } from "@/services/shareledger";
import { AssetMovementService } from "@/services/assetMovement";
import { DevService } from "@/services/dev/dev";
import { ChatbotService } from "@/services/chatbot";
import { WebSocketService } from "@/services/webSocket";
import { UniverseService } from "@/services/universe";

import {
    CurrencyService,
    TimeService,
    ChartService,
    ReactiveService,
    AppBarService,
    UiPlugin,
    PollService,
    TextService,
    DialogPlugin
} from "@equisafe-ui-vue";

import ServicesPlugin from "@/services/plugins/services";
import { LanguageService } from "@/services/i18n";
import { getStatusCodeError } from "@/errors/index.js";

// Plugins
import PermissionsPlugin from "@/services/plugins/permissions";
import { i18n } from "@/services/plugins/i18n";
import PortalVue from "portal-vue";
// vue lib
import router from "@/router/router";
import store from "@/store/store";

const globalComponents = {
    GlobalTransactionChip,
    GlobalCopyBadge,
    GlobalPlatformsLinks,
    GlobalChooseEntity,
    GlobalInviteEntityDialog,
    GlobalCompanyInviteByInvestorDialog,
    GlobalPeopleList,
    GlobalDocWithCategoryList,
    GlobalFinalState,
    GlobalInviteInvestorDialog,
    GlobalExplorerDialog,
    GlobalChooseInvestor,
    GlobalChooseShareledger,
    GlobalSelectInvestorTable,
    GlobalYousignIFrame
};
import { ColorService } from "@equisafe-ui-vue/src/services/color";
import { SecondaryMarketService } from "../services/secondarymarket";

export class EqsLib {
    constructor(vue, test = false) {
        this.test = test;

        // services and plugins
        const apiService = new APIService();
        const roleService = new RoleService(apiService, store);
        const entityService = new EntityService(apiService, roleService, store);
        const languageService = new LanguageService(apiService, entityService, i18n);
        const currencyService = new CurrencyService(languageService);
        const time = new TimeService();
        const pollService = new PollService();
        const dev = new DevService();
        const chatbot = new ChatbotService(dev, time, languageService);

        const services = {
            api: apiService,
            poll: pollService,
            kyc: new KYCService(apiService),
            role: roleService,
            entity: entityService,
            notifications: new NotificationsService(apiService, store, router),
            blockchain: new BlockchainService(
                apiService,
                store,
                entityService,
                roleService,
                pollService
            ),
            dev,
            chatbot,
            shareledger: new ShareledgerService(apiService, roleService, store),
            assetMovement: new AssetMovementService(apiService, store),
            secondaryMarket: new SecondaryMarketService(apiService)
        };

        if (!this.test) {
            Object.keys(globalComponents).forEach(key => vue.component(key, globalComponents[key]));
            Object.assign(services, {
                currency: currencyService,
                time,
                chart: new ChartService(),
                color: new ColorService(),
                reactive: new ReactiveService(),
                appBar: new AppBarService(chatbot),
                universe: new UniverseService(apiService, store),
                language: languageService,
                webSocket: new WebSocketService(apiService, store),
                text: new TextService()
            });
        }
        vue.use(ServicesPlugin, services);
        vue.use(PermissionsPlugin, {
            store
        });
        if (!this.test) {
            vue.use(DialogPlugin);
            vue.use(UiPlugin, {
                getStatusCodeError
            });
            vue.use(PortalVue);
        }
    }
    setup(mocks = {}) {
        let plugins = {
            router,
            i18n,
            store
        };

        if (this.test) {
            const testMock = {
                mocks: {
                    ...mocks
                }
            };
            window.app = {
                $t: key => key
            };
            Object.assign(plugins, testMock);
        }
        return plugins;
    }
    setWindow(app, window) {
        this.window = window;
        window.app = app;
    }
    cleanWindow(window) {
        window = this.window;
    }
}
