import Vue from "vue";

var state = Vue.observable({
    actionBar: false,
    loading: false
});

export class AppBarService {
    constructor(chatbot) {
        state.actionBar = false;
        this.state = state;
        if (!chatbot) console.warn("chatbot is not passed into AppBarService.");
        this.chatbot = chatbot;
    }
    activateActionBar(val = true) {
        state.actionBar = val;
    }
    loading(val) {
        state.loading = val;
    }
}
