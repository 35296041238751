<template>
    <div class="d-flex flex-column fill-height">
        <v-row class="fill-height">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <v-row class="px-3">
                    <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsText
                            :label="$t('signatures.procedure.name.label')"
                            :placeholder="$t('signatures.procedure.name.placeholder')"
                            :value.sync="formData.name"
                            v-bind="{ loading, ...schemaDouble.name }"
                        />
                    </v-col>
                    <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsTextArea
                            :label="$t('signatures.procedure.description.label')"
                            :placeholder="$t('signatures.procedure.description.placeholder')"
                            :value.sync="formData.description"
                            v-bind="{
                                loading,
                                ...schemaDouble.description,
                                rows: 2,
                                counter: 255
                            }"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field fill-height">
                <EqsLabel
                    class="pa-3"
                    editable
                    :value="$t('signatures.add.signers')"
                    v-bind="{ loading, ...schemaDouble.signer_set }"
                >
                    <GlobalSelectInvestorTable
                        class="fill-height"
                        :value.sync="formData.signer_set"
                        v-bind="{
                            loading,
                            ...schemaDouble.signer_set
                        }"
                    />
                </EqsLabel>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Parameters",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
