<template>
    <v-container fluid class="ma-0 pa-0">
        <EqsTextDisplay
            class="pa-3"
            v-bind="{
                value: $t('capital-increase.admin.detail.evolution-fund.title'),
                eqsType: 'subtitle'
            }"
        />
        <v-container fluid class="ma-0 pa-0">
            <v-row v-if="!capitalIncrease.public">
                <v-col cols="3">
                    <EqsColoredCard
                        eqs-type="orange"
                        :label="$t('label.starting')"
                        :txt="daysFromStart"
                    />
                </v-col>
                <v-col />
                <v-col cols="3">
                    <EqsBtn
                        x-large
                        el-class="publish-offer"
                        color="primary"
                        @click="
                            () => {
                                value = true;
                            }
                        "
                    >
                        {{ $t("capital-increase.admin.detail.fundraising-publish") }}
                    </EqsBtn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col
                    :cols="
                        $reactive.getVal({
                            mobile: 6,
                            tablet: 3,
                            desktop: 3
                        })
                    "
                >
                    <EqsColoredCard
                        v-if="capitalIncrease.phase !== 'pending'"
                        :label="isPastEndDate ? $t('transaction.ended') : $t('transaction.ending')"
                        :txt="daysFromEnd"
                    />
                    <EqsColoredCard v-else :label="$t('label.starting')" :txt="daysFromStart" />
                </v-col>
                <v-col
                    :cols="
                        $reactive.getVal({
                            mobile: 6,
                            tablet: 3,
                            desktop: 3
                        })
                    "
                >
                    <EqsColoredCard
                        eqs-type="green"
                        :label="$t('capital-increase.admin.detail.info-card.header-shares')"
                        :txt="
                            capitalIncrease.reserved_shares
                                ? capitalIncrease.reserved_shares.toString()
                                : '0'
                        "
                    />
                </v-col>
                <v-col
                    :cols="
                        $reactive.getVal({
                            mobile: 6,
                            tablet: 3,
                            desktop: 3
                        })
                    "
                >
                    <EqsColoredCard
                        eqs-type="green"
                        :label="$t('capital-increase.admin.detail.info-card.header-investors')"
                        :txt="
                            capitalIncrease.investor_count
                                ? capitalIncrease.investor_count.toString()
                                : '0'
                        "
                    />
                </v-col>
                <v-col
                    :cols="
                        $reactive.getVal({
                            mobile: 6,
                            tablet: 3,
                            desktop: 3
                        })
                    "
                >
                    <EqsColoredCard
                        eqs-type="green"
                        :label="$t('capital-increase.admin.detail.info-card.header-money-raised')"
                        :txt="$currency.display(capitalIncrease.money_raised)"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <MoneyRaised
                        v-bind="{
                            value: parseFloat(capitalIncrease.money_raised),
                            softCap: parseFloat(capitalIncrease.soft_cap),
                            max: parseFloat(capitalIncrease.hard_cap)
                        }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="capitalIncrease.public">
                <v-col cols="auto">
                    <EqsBtn
                        large
                        el-class="publish-offer"
                        color="primary"
                        @click="
                            () => {
                                inviteDialog = true;
                            }
                        "
                    >
                        <template #with-icon>
                            <v-icon left> mdi-send </v-icon>
                            {{ $t("capital-increase.admin.detail.invite-title") }}
                        </template>
                    </EqsBtn>
                </v-col>
                <v-col cols="auto">
                    <EqsBtn
                        v-if="capitalIncrease.universe === 'kyoseil'"
                        large
                        el-class="export-pec"
                        color="primary"
                        :disabled="localLoading"
                        @click="exportPECFile"
                    >
                        <template #with-icon>
                            <v-icon v-if="!localLoading" left> mdi-export-variant </v-icon>
                            <v-progress-circular v-else indeterminate color="primary" />
                            {{ $t("capital-increase.admin.detail.export-pec") }}
                        </template>
                    </EqsBtn>
                </v-col>
            </v-row>
        </v-container>
        <EqsDialog
            v-model="inviteDialog"
            closable
            persistent
            v-bind="{
                title: $t('capital-increase.admin.detail.invite-title'),
                confirm: {
                    txt: $t('capital-increase.admin.detail.invite-button'),
                    fn: inviteEntities
                },
                cancel: {}
            }"
            @close="() => (inviteDialog = false)"
        >
            <GlobalSelectInvestorTable
                class="fill-height"
                :value.sync="investors"
                v-bind="{
                    loading: loadingInvite,
                    isRequired: true
                }"
            />
        </EqsDialog>
        <EqsDialog
            :value.sync="value"
            persistent
            v-bind="{ eqsType: 'default', eqsDataType: ['default', 'no-padding'] }"
        >
            <template #main>
                <OfferCard
                    v-bind="{
                        item: capitalIncrease,
                        selected: true,
                        clickable: false,
                        height: $reactive.getVal({
                            mobile: '29rem',
                            tablet: '31rem',
                            desktop: '33rem'
                        })
                    }"
                >
                    <template #actions>
                        <EqsTextDisplay class="pt-3 pb-3 text-center">
                            {{ $t("capital-increase.admin.detail.publish-txt") }}
                        </EqsTextDisplay>
                        <v-row class="justify-center align-center">
                            <EqsBtn
                                outlined
                                class="mx-3"
                                el-class="dialog-cancel-publish"
                                v-bind="{
                                    color: 'primary',
                                    action: () => {
                                        value = false;
                                    }
                                }"
                            >
                                {{ $t("label.cancel") }}
                            </EqsBtn>
                            <EqsBtn
                                class="mx-3"
                                el-class="dialog-confirm-publish"
                                v-bind="{ color: 'success', action: publishNow }"
                            >
                                {{ $t("label.publish") }}
                            </EqsBtn>
                        </v-row>
                    </template>
                </OfferCard>
            </template>
        </EqsDialog>
    </v-container>
</template>

<script>
import FileSaver from "file-saver";

import MoneyRaised from "@/components/molecules/progress/MoneyRaised.vue";
import OfferCard from "@/components/molecules/card/OfferCard";

import { calcPercent } from "@equisafe-ui-shared/util";
import capitalize from "lodash/capitalize";
import { mapActions } from "vuex";

export default {
    name: "CiFundraising",
    components: {
        MoneyRaised,
        OfferCard
    },
    props: {
        capitalIncrease: {
            type: Object,
            default: null
        },
        sendShares: {
            type: Boolean
        }
    },
    data() {
        return {
            calcPercent,
            localLoading: false,
            value: false,
            inviteDialog: false,
            investors: [],
            loadingInvite: false,
            mainTxt: this.$t("capital-increase.admin.detail.evolution-fund.txt")
        };
    },
    computed: {
        isPastEndDate() {
            return this.$time.isPast(this.capitalIncrease.end_date);
        },
        daysFromStart() {
            return capitalize(this.$time.fromNowHR(this.capitalIncrease.start_date, true));
        },
        daysFromEnd() {
            return capitalize(this.$time.fromNowHR(this.capitalIncrease.end_date, true));
        }
    },
    methods: {
        ...mapActions(["updateMyProject"]),
        sendSharesBtn() {
            this.$emit("update:sendShares", true);
            setTimeout(() => {
                const card = document.querySelector(".eqs-card-3");
                if (card) {
                    card.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 300);
        },
        async publishNow() {
            try {
                const capinc = await this.updateMyProject({
                    id: this.capitalIncrease.id,
                    public: true
                });
                this.$ui.success(
                    this.$t("ui.message.success.capital-increase", {
                        capitalIncrease: this.capitalIncrease.order_title
                    })
                );
                this.$emit("update:capitalIncrease", capinc);
                this.value = false;
            } catch (e) {
                this.$ui.error(e, "capital_increase_admin_make_public");
                throw e;
            }
        },
        async inviteEntities() {
            try {
                this.loadingInvite = true;
                this.$api.inviteCapitalIncrease({
                    capital_increase: this.capitalIncrease.url,
                    visible_by: this.investors.map(investor => investor.entity.id)
                });
                this.$ui.success(
                    this.$tc("capital-increase.admin.detail.invite-success"),
                    this.investors.length
                );
            } catch (e) {
                this.$ui.error(e, "capital_increase_admin_invite");
            } finally {
                this.loadingInvite = false;
                this.investors = [];
            }
        },
        async exportPECFile() {
            if (this.localLoading) {
                return;
            }
            try {
                this.localLoading = true;
                const res = await this.$api.request(
                    "get",
                    `/api/capital-increase/${this.capitalIncrease.id}/export_kyoseil_as_pec/`
                );
                FileSaver.saveAs(
                    new Blob([res.data]),
                    `export_pec_${this.capitalIncrease.order_title}.csv`
                );
            } catch (e) {
                console.error("exportPECFile", e);
                this.$ui.error(`Cannot export PEC file (${e.statusText})`);
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
