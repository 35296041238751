export function getHttpErrMsg(errObj, msg = "") {
    let errMsg = "";
    if (errObj.data && errObj.data.startsWith("<!DOCTYPE html>")) {
        // Erreur non prévue, on affiche pas l'erreur
        errMsg = `(${errObj.status} - ${errObj.statusText})`;
    } else {
        errMsg = `${errObj.data} (${errObj.status} - ${errObj.statusText})`;
    }
    if (msg?.length > 0) {
        return `${msg} -> ${errMsg}`;
    } else {
        return errMsg;
    }
}
