import { render, staticRenderFns } from "./Parameters.vue?vue&type=template&id=44bc3184&scoped=true"
import script from "./Parameters.vue?vue&type=script&lang=js"
export * from "./Parameters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bc3184",
  null
  
)

export default component.exports