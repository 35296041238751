<template>
    <transition name="eqs-slide-from-right" class="d-flex fill-height fill-width">
        <v-container v-if="isRoot" fluid class="ma-0 pa-0">
            <EqsTabs
                :value.sync="tab"
                v-bind="{
                    tabs,
                    eqsType: 'default'
                }"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        v-bind="{
                            loading:
                                loading || investorsLoading || shareledgersLoading || localLoading,
                            dashboardData: dashboardsData[item.section],
                            investorsWithShares
                        }"
                    />
                </template>
            </EqsTabs>
        </v-container>
        <router-view
            v-else
            v-bind="{
                fetch,
                shareledgers: dashboardsData.parameters.shareledgers,
                loading: loading || investorsLoading || shareledgersLoading || localLoading
            }"
            :selected-shareledger="selectedShareledger"
            :entity="selectedShareholder"
            @close="closeDialog"
            @closeDigitalizeLedger="closeDigitalizeLedger"
        />
    </transition>
</template>

<script>
import Dashboard1 from "./dashboard1/_Dashboard1";
import DashboardLedgers from "./dashboardLedgers/_DashboardLedgers";
import Parameters from "./parameters/_Parameters";
import { mapGetters, mapActions } from "vuex";
import { createWorkbook } from "@/services/excelJs";

export default {
    name: "Capitalisation",
    components: {
        Dashboard1,
        DashboardLedgers,
        Parameters
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: null,
            localLoading: true,
            selectedShareholder: null,
            selectedShareledger: null
        };
    },
    computed: {
        dashboardsData() {
            return {
                general: {
                    cards: {
                        capital: {
                            subtitle: this.$t("capitalisation.general-view.capital-title"),
                            infoTxt: this.$t("capitalisation.general-view.capital-info"),
                            subtitle_type: "body",
                            isCurrency: true,
                            amount: this.capital
                        },
                        beneficiaries: {
                            subtitle: this.$t("capitalisation.general-view.beneficiaries-title"),
                            subtitle_type: "body",
                            infoTxt: this.$t("capitalisation.general-view.beneficiaries-info"),
                            amount: this.investorsWithShares.length
                        },
                        valorization: {
                            subtitle: this.$t("capitalisation.general-view.valorization-title"),
                            subtitle_type: "body",
                            infoTxt: this.$t("capitalisation.general-view.valorization-info"),
                            isCurrency: true,
                            abreviate: false,
                            amount: this.valorisation
                        },
                        funding: {
                            subtitle: this.$t("capitalisation.general-view.funding-title"),
                            infoTxt: this.$t("capitalisation.general-view.funding-info"),
                            isCurrency: true,
                            subtitle_type: "body",
                            abreviate: false,
                            amount: 0
                        }
                    },
                    table: {
                        items: this.investorsWithShares
                    }
                },
                shareCapital: {
                    cards: {
                        capital: {
                            subtitle: this.$t("capitalisation.general-view.capital-title"),
                            infoTxt: this.$t("capitalisation.general-view.capital-info"),
                            isCurrency: true,
                            subtitle_type: "body",
                            amount: this.capital
                        },
                        shareholders: {
                            subtitle: this.$t("capitalisation.general-view.shareholders-title"),
                            amount: 0,
                            subtitle_type: "body"
                        },
                        nominalVal: {
                            subtitle: this.$t("capitalisation.general-view.nominal-value-title"),
                            isCurrency: true,
                            subtitle_type: "body",
                            amount: this.capital / this.total_number_of_shares
                        },
                        lastRound: {
                            subtitle: this.$t("capitalisation.general-view.last-round-title"),
                            infoTxt: this.$t("capitalisation.general-view.last-round-info"),
                            isCurrency: true,
                            abreviate: false,
                            subtitle_type: "body",
                            amount: 0
                        }
                    },
                    table: {
                        items: []
                    }
                },
                byLedger: {
                    shareledgers: this.shareledgers
                },
                parameters: {
                    shareledgers: this.shareledgers
                }
            };
        },
        isRoot() {
            return this.$route.name === "capitalisation";
        },
        tabs() {
            if (this.$entity.represented().has_digitalized_shareledger)
                return [
                    {
                        id: "general",
                        title: this.$t("capitalisation.general-view.title"),
                        item: {
                            is: "Dashboard1",
                            section: "general"
                        }
                    },
                    {
                        id: "capital",
                        title: this.$t("capitalisation.share-capital.title"),
                        item: {
                            is: "Dashboard1",
                            section: "shareCapital"
                        }
                    },
                    {
                        id: "ledger",
                        title: this.$t("capitalisation.by-ledger.title"),
                        item: {
                            is: "DashboardLedgers",
                            section: "byLedger"
                        }
                    },
                    {
                        id: "parameters",
                        title: this.$t("capitalisation.parameters.title"),
                        item: {
                            is: "Parameters",
                            section: "parameters"
                        }
                    }
                ];
            else {
                return [
                    {
                        id: "parameters",
                        title: this.$t("capitalisation.digitalize-ledger.title"),
                        item: {
                            is: "Parameters",
                            section: "parameters"
                        }
                    }
                ];
            }
        },
        ...mapGetters([
            "shareledgers",
            "shareledgersLoading",
            "investors",
            "investorsWithShares",
            "investorsLoading",
            "capital",
            "total_number_of_shares",
            "valorisation"
        ])
    },
    watch: {
        investorsWithShares: {
            immediate: true,
            handler(val) {
                // todo, all those values should be returned by backend on a light query
                this.dashboardsData.general.cards.funding.amount = val.reduce(
                    (acc, cur) => acc + Math.abs(cur.amount),
                    0
                );
                const shareholders = this.getShareholders(val);
                this.dashboardsData.shareCapital.table.items = shareholders;
                this.dashboardsData.shareCapital.cards.shareholders.amount = shareholders.length;
                const chart = this.getInvestorsChartConfig(val);
                this.dashboardsData.general.chart = chart;
                const chart2 = { ...chart };
                this.dashboardsData.shareCapital.chart = chart2;
            }
        }
    },
    async beforeMount() {
        await this.fetch();
    },
    async created() {
        // TODO remove this because will be hable to handle that on Vuex shareledgers
        if (!this.$entity.represented().has_digitalized_shareledger)
            await this.$role.updateMyCurrentRole(this.$entity.represented().id);
        this.getInvestors();
        this.getShareledgers();
    },
    methods: {
        ...mapActions(["getShareledgers", "getInvestors"]),
        getInvestorsChartConfig(investors) {
            let labels = [];
            let data = [];
            let infos = [];
            let largestInvestors = investors
                .filter(investor => !!investor.percent && investor.percent > 0)
                .sort((a, b) => a.percent < b.percent);
            // .slice(0, 5); // get 5 largest investors
            for (let investor of largestInvestors) {
                labels.push(investor.entity.name);
                const info = {
                    name: investor.entity.name,
                    investment: this.$currency.display(investor.amount),
                    interest: this.$currency.percent(investor.percent, "0.00%")
                };
                infos.push(info);
                //data.push({ name: investor.entity.name, interest: investor.percent, });
                data.push((investor.percent * 100).toFixed(2));
            }
            return {
                labels,
                legend: {
                    position: "top" // 'bottom', 'left', 'top'
                },

                series: [
                    {
                        label: {
                            enabled: false
                        },
                        type: "pie",
                        labelKey: "name",
                        angleKey: "interest",
                        innerRadiusOffset: -70
                    }
                ],
                data,
                infos,
                colorIndex: 2,
                name: {
                    formatter: function (value, ctx) {
                        return ctx.active
                            ? ctx.dataset.datalabels.infos[ctx.dataIndex].name
                            : ctx.dataset.datalabels.infos[ctx.dataIndex].name;
                    }
                },
                value: {
                    formatter: function (value, ctx) {
                        return ctx.active
                            ? ctx.dataset.datalabels.infos[ctx.dataIndex].investment
                            : ctx.dataset.datalabels.infos[ctx.dataIndex].interest;
                    }
                },
                datalabels: {
                    display: function (ctx) {
                        return ctx.dataset.data[ctx.dataIndex] >= 5.0;
                    }
                },
                tooltips: {
                    enabled: true
                }
            };
        },
        getShareholders(investors) {
            return investors
                .filter(act => {
                    for (let s of act.shares) {
                        if (s.share_type === "shares") {
                            return true;
                        }
                    }
                    return false;
                })
                .map(act => {
                    let res = { ...act };
                    res.shares = res.shares.filter(s => s.share_type === "shares");
                    return res;
                });
        },
        async fetch() {
            try {
                this.localLoading = true;
                await this.fetchCompanyShareledgers();
            } catch (e) {
                this.$ui.error(e, "company_capitalisation_ledger_dashboard");
            } finally {
                this.localLoading = false;
            }
        },
        closeDialog() {
            this.$router
                .push({
                    name: "capitalisation",
                    params: {
                        companyId: this.$entity.represented().id
                    }
                })
                .catch(() => {});
        },
        async closeDigitalizeLedger() {
            this.closeDialog();
            await this.fetch();
        },
        async fetchCompanyShareledgers() {
            try {
                // const dashboard = await this.$api.captableCompanyShareledgerList(
                //   this.$entity.represented().id
                // );
                // this.dashboardsData.byLedger.shareledgers = dashboard.data;
                // this.dashboardsData.parameters.shareledgers = dashboard.data;
                // let capital = dashboard.data.reduce(
                //   (acc, cur) =>
                //     acc +
                //     (cur.share_type === "shares"
                //       ? cur.numberofshares * cur.nominal
                //       : 0),
                //   0
                // );
                // let nombre = dashboard.data.reduce(
                //   (acc, cur) =>
                //     acc + (cur.share_type === "shares" ? cur.numberofshares : 0),
                //   0
                // );
                // let valorisation = dashboard.data.reduce(
                //   (acc, cur) =>
                //     acc +
                //     (cur.share_type === "shares"
                //       ? cur.numberofshares * cur.lastpricepershare
                //       : 0),
                //   0
                // );
                // this.dashboardsData.general.cards.capital.amount = capital;
                // this.dashboardsData.shareCapital.cards.capital.amount = capital;
                // this.dashboardsData.general.cards.valorization.amount = valorisation;
                // this.dashboardsData.shareCapital.cards.nominalVal.amount =
                //   capital / nombre;
            } catch (e) {
                throw e;
            }
        }
    }
};
</script>
